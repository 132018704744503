/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'elements/Hero/HeroDefault'
import Breadcrumb from 'elements/Breadcrumb'
import AllNews from 'elements/AllNews'

// Components
import SEO from 'shared/SEO'
import Layout from 'layout/Layout'

const PageTemplate = ({
  data: {
    page: { title, paginaNieuws, seo },
  },
  pageContext,
}) => {
  return (
    <Layout withForm>
      <SEO seo={seo} />

      <HeroDefault fields={paginaNieuws.paginanieuwsbanner} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <AllNews className="py-lg-5 py-4" />

      <div style={{ height: '100px' }} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wpPage(databaseId: { eq: $wordpress_id }) {
      ...NieuwsPageFrag
    }
  }
`

export default PageTemplate
