import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Elements
import NewsPreview from 'components/elements/NewsPreview'

// Third Party
import styled from 'styled-components'

const Title = styled.h2`
  font-size: ${(props) => props.theme.font.size.ml};
  font-weight: ${(props) => props.theme.font.weight.xl};
  color: ${(props) => props.theme.color.text.secondary};
`

const StyledNews = styled.div``

const AllNews = ({ className }) => {
  const {
    posts: { nodes: posts },
  } = useStaticQuery(graphql`
    {
      posts: allWpPost(sort: { fields: date, order: DESC }) {
        nodes {
          ...NieuwsPreviewFrag
        }
      }
    }
  `)

  return (
    <StyledNews className={`${className ? `${className}` : ``}`}>
      <div className="container">
        <Title className="mb-5">NIEUWS</Title>
        <div className="row">
          {posts.map((p, index) => (
            <div className="col-sm-8 col-md-6 col-lg-4 pb-lg-0 pb-5">
              <NewsPreview p={p} index={index} />
            </div>
          ))}
        </div>
      </div>
    </StyledNews>
  )
}

export default AllNews
